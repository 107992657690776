const firebaseConfig = {
  projectId: "constantia-edge",
  appId: "1:579843504991:web:8a85fe9206e2be02e514ea",
  databaseURL:
    "https://constantia-edge-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "constantia-edge.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyCWbn_1iDnRyIZpXRfzDbHznmsEYk6fSsA",
  authDomain: "constantia-edge.firebaseapp.com",
  messagingSenderId: "579843504991",
  measurementId: "G-6GCE73PXMH",
};

export default firebaseConfig;
